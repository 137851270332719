import { cloneObj } from "./utils";
// 行业品类
export const brandCates = {
  label: "行业品类",
  key: "industry",
  submenu: [
    {
      label: "不限",
      value: "0",
      submenu: [
        {
          label: "不限",
          value: "0_0",
        },
      ],
    },
    {
      label: "餐饮",
      value: "1",
      submenu: [
        { label: "全部餐饮", value: "1_0", level: 1, filter: ["eq", "餐饮"] },
        { label: "小吃快餐", value: "1_1" },
        { label: "休闲餐饮", value: "1_2" },
        { label: "地饭菜", value: "1_3" },
        { label: "火锅", value: "1_4" },
        { label: "烧烤", value: "1_5" },
        { label: "外国餐饮", value: "1_6" },
        { label: "其他餐饮", value: "1_7" },
      ],
    },
    {
      label: "零售",
      value: "2",
      submenu: [
        { label: "全部零售", value: "2_0", level: 1, filter: ["eq", "零售"] },
        { label: "服装鞋包", value: "2_1" },
        { label: "时尚精品", value: "2_2" },
        { label: "购物场所", value: "2_3" },
        { label: "专卖店", value: "2_4" },
        { label: "其他零售", value: "2_5" },
      ],
    },
    {
      label: "汽车",
      value: "5",
      submenu: [
        { label: "全部汽车", value: "5_0", level: 1, filter: ["eq", "汽车"] },
        { label: "汽车销售", value: "5_1" },
      ],
    },
    {
      label: "生活服务",
      value: "4",
      submenu: [
        {
          label: "全部生活服务",
          value: "4_0",
          level: 1,
          filter: ["eq", "生活服务"],
        },
        { label: "便民服务", value: "4_1" },
        { label: "教育培训", value: "4_2" },
        { label: "酒店", value: "4_3" },
        { label: "其他生活服务", value: "4_4" },
      ],
    },
    {
      label: "休闲娱乐",
      value: "3",
      submenu: [
        {
          label: "全部休闲娱乐",
          value: "3_0",
          level: 1,
          filter: ["eq", "休闲娱乐"],
        },
        { label: "娱乐场所", value: "3_1" },
        { label: "运动场馆", value: "3_2" },
      ],
    },
  ],
};
let secondCateList = [
  {
    label: "不限",
    value: "",
  },
];
let cateFilterObjs = {};
for (let i = 0, n = brandCates.submenu.length; i < n; i++) {
  let item = brandCates.submenu[i];
  if (item.submenu && item.submenu.length > 0) {
    for (let j = 0, m = item.submenu.length; j < m; j++) {
      let item2 = item.submenu[j];
      cateFilterObjs[item2.value] = item2;
      if (item2.level === 1) {
        continue;
      }
      secondCateList.push({
        label: item2.label,
        value: item2.value,
      });
    }
  }
}
// 获取行业品类label
export const getCateLabel = (key) => {
  let item = cateFilterObjs[key];
  return (item && item.label) || "";
};
// 二级行业品类
export const brandSecondCates = secondCateList;

// 品牌筛选菜单
export const brandFilterMenus = [
  {
    label: "占地面积",
    key: "area",
    name: "占地面积",
    submenu: [
      {
        label: "不限",
        value: "0",
      },
      {
        label: "5万㎡以下",
        value: "1",
      },
      {
        label: "5-10万㎡",
        value: "2",
      },
      {
        label: "10-20万㎡",
        value: "3",
      },
      {
        label: "20万㎡以上",
        value: "4",
      },
    ],
  },
  {
    label: "商圈级别",
    key: "level",
    name: "商圈级别",
    submenu: [
      {
        label: "不限",
        value: "0",
      },
      {
        label: "区域商圈",
        value: "1",
      },
      {
        label: "市级商圈",
        value: "2",
        filter: ["lt", 50],
      },
    ],
  },
  {
    label: "商圈等级",
    key: "type",
    name: "商圈等级",
    submenu: [
      {
        label: "不限",
        value: "0",
      },
      {
        label: "成熟商圈",
        value: "1",
        filter: ["lt", 20],
      },
      {
        label: "新兴商圈",
        value: "2",
        filter: ["bet", 20, 30],
      },
    ],
  },
];

let filterObjs = {};
for (let i = 0, n = brandFilterMenus.length; i < n; i++) {
  let item = brandFilterMenus[i];
  if (!item.key) {
    continue;
  }
  filterObjs[item.key] = item;
}
// 默认排序key
export const brandNormalOrder = {
  field: "storeNumber",
  condition: "desc",
};
// 排序列表
export const brandOrderList = [
  {
    label: "覆盖省",
    key: "",
  },
  {
    label: "覆盖市",
    key: "",
  },
  {
    label: "门店数量",
    key: "",
  },
  {
    label: "日均客流",
    key: "",
    isVip: true,
  },
  {
    label: "日均销量",
    key: "",
    isVip: true,
  },
  {
    label: "日均广告投放",
    key: "",
    isVip: true,
  },
];
export const brandLabelKeys = {
  品牌id: "code",
  品牌名称: "name",
  标签: "tags",
  人均消费: "consumptionPerPerson",
  门店数量: "storeNumber",
  已入驻购物中心数量: "coverShoppingNumber",
};

const filterConds = {
  eq: 0,
  gt: 1,
  lt: 2,
  gte: 3,
  lte: 4,
  bet: 5,
  in: 6,
  neq: 7,
};
// 筛选项
export const getFilter = (key, _values) => {
  let valueItem = null;
  let filterField = "";
  if (key === brandCates.key) {
    let value = _values[0];
    valueItem = cateFilterObjs[value];
    if (valueItem.level === 1) {
      filterField = "业态大类";
    } else {
      filterField = "业态中类";
    }
  } else {
    let filterObj = filterObjs[key] || {};
    filterField = filterObj.name;
    let submenus = (filterObj && filterObj.submenu) || [];
    let value = _values[0];
    for (let i = 0, n = submenus.length; i < n; i++) {
      let item = submenus[i];
      if (item.value === value) {
        valueItem = cloneObj(item);
        break;
      }
    }
  }

  if (!valueItem.filter) {
    valueItem.filter = ["eq", valueItem.label];
  }
  let cond = valueItem.filter[0];
  let values = [];
  for (let i = 1, n = valueItem.filter.length; i < n; i++) {
    let val = valueItem.filter[i];
    values.push(val);
  }
  let filter = {
    field: filterField,
    cond: filterConds[cond] || 0,
    value: values,
  };
  return filter;
};
// 门店请求参数
export const storeMetas = {
  "品牌-门店详情": {
    identify: "sg-data-品牌-门店详情",
    fields:
      "品牌名称,门店名称,门店地址,城市,lon,lat,品牌分类,图片url,更新时间,版本号,geom,省名称,省编码,城市名称,市编码,区县名称,区县编码",
    filters: [
      {
        field: "品牌id",
      },
    ],
  },
  "品牌-品牌省市区门店数统计": {
    identify: "sg-data-品牌-品牌省市区门店数统计",
    fields: "品牌名称,地区级别,地区名称,地区编码,门店数",
    filters: [
      {
        field: "品牌id",
      },
    ],
  },
};
export const getParams = (tag, brandId, _filters) => {
  let obj = cloneObj(storeMetas[tag]);
  let fields = obj.fields;
  if (typeof fields === "string") {
    fields = fields.split(",");
  }
  let filters = obj.filters;
  for (let i = 0, n = filters.length; i < n; i++) {
    let item = filters[i];
    item.cond = 0;
    if (item.field === "品牌id") {
      item.value = [brandId];
    }
    filters[i] = item;
  }
  if (_filters) {
    filters.push(..._filters);
  }
  let params = {
    identify: obj.identify,
    filters: filters,
    fields: fields,
    sort: ["品牌id", "desc"],
    pages: {
      page: 1,
      size: 100,
    },
  };
  return params;
};
