import { SearchData, GetCommonInfo, GetDetailBrowsingRecord } from "@/service";

import { brandNormalOrder, brandFilterMenus, getFilter } from "js/business.js";
import { formatFieldsList, login, openNewWindow } from "js/utils.js";
import { User } from "../../service/user";
const brandMixin = {
  data() {
    return {
      isLoading: true,
      page: 1,
      pageSize: 20,
      total: 0,
      brandList: [],
      curFilterData: {},
      isFirstGetBrandList: true,
      tableFields: [
        "",
        "商圈名称",
        "商圈级别",
        "商圈等级",
        "商业数",
        "覆盖省数量",
        "覆盖市数量",
        "已入驻购物中心数量",
        "品牌图片地址",
        "经营模式",
        "业态分类",
      ],
      searchKey: "",
      cityCode: "110000",
      filterCondition: [],
      filterData: {},
    };
  },
  computed: {
    isUserLogin() {
      return this.$store.state.isUserLogin;
    },
    sort() {
      return this.$store.getters.getSortId("business");
    },
  },

  watch: {
    filterData: {
      handler(value) {
        let tempData = {};
        if (value) {
          for (let key in value) {
            tempData[key] = value[key];
            this.$set(this.curFilterData, key, tempData[key]);
          }
          this.initTable();
        }
      },
      deep: true,
    },
    pages: {
      handler(value) {
        this.page = value.page;
        this.pageSize = value.pages;
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.query.key) {
      this.searchKey = this.$route.query.key;
    }
  },
  methods: {
    //初始化过滤器数据
    initData() {
      this.initTable();
      // if (this.brandList.length > 0) {
      //   let brandId = this.brandList[0].code;
      //   this.handleSelectBrand(brandId);
      // }
    },
    //请求商品列表数据
    async initTable() {
      this.isLoading = true;
      this.filterCondition = [];
      this.filterCondition = [
        {
          field: "cityCode",
          condition: [this.cityCode],
        },
      ];
      for (let key in this.curFilterData) {
        let values = this.curFilterData[key] || [];
        if (values.length === 0 || (values.length === 1 && values[0] === "0")) {
          continue;
        }
        // let filter = getFilter(key, values);
        let filter = {
          field: key,
          condition: values,
        };
        this.filterCondition.push(filter);
      }

      let params = {
        searchSort: this.sort,
        word: this.searchKey,
        page: this.page || 1,
        pageSize: this.pageSize || 10,
        filterCondition: this.filterCondition || [],
        orderCondition: {
          // field: this.sortField || "",
          // condition: this.condition || ""
        },
        lat: "",
        lon: "",
      };

      let res = await SearchData(params);
      if (res && res.code === 200) {
        let list = res.data.items || [];
        // 查询收藏状态
        list = await this.checkListCollectStatus(list);
        this.brandList = list || [];
        if (this.isFirstGetBrandList) {
          this.isFirstGetBrandList = false;
          if (this.brandList.length > 0) {
            let brandId = this.brandList[0].code;
            // this.handleSelectBrand(brandId);
          }
        }
        this.total = res.data.pages.total;
        this.isLoading = false;
        this.getMapPolygon(list);
      }
    },
    async getCollectListByCodes(codes) {
      let params = {
        searchSort: this.sort,
        word: this.searchKey,
        page: this.page || 1,
        pageSize: this.pageSize || 10,
        filterCondition: [],
        orderCondition: {
          // field: this.sortField || "",
          // condition: this.condition || ""
        },
        lat: "",
        lon: "",
      };
      let res = await SearchData(params);
      if (res && res.code === 200) {
        let list = res.data.items || [];
        let showList = [];
        list.map((item) => {
          if (codes.indexOf(item.code) !== -1) {
            item.isCollect = true;
            showList.push(item);
          }
        });
        return showList;
      }
      return [];
    },
    // 添加收藏
    async addCollect(code) {
      if (!this.checkLogin()) {
        return;
      }
      let params = {
        sort: this.sort,
        code: code,
      };
      let res = await User.addCollection(params);
      if (res && res.code === 200) {
        this.$Message.success((res && res.msg) || "收藏成功");
        if (this.isShowCollection) {
          this.getCollectList();
        } else {
          this.initTable();
        }
      }
    },
    // 取消收藏
    async delCollect(code) {
      if (!this.checkLogin()) {
        return;
      }
      this.$global.confirm({
        title: "提示",
        content: "确认要取消收藏吗？",
        ok: async () => {
          let params = {
            sort: this.sort,
            code: code,
          };
          let res = await User.deleteCollection(params);
          if (res && res.code === 200) {
            if (this.isShowCollection) {
              this.getCollectList();
            } else {
              this.initTable();
            }
            this.$Message.info((res && res.msg) || "取消成功");
          }
        },
      });
    },
    checkLogin() {
      if (!this.isUserLogin) {
        this.$global.confirm({
          title: "提示",
          content: "请先登录",
          ok: () => {
            login();
          },
        });
        return false;
      }
      return true;
    },
    //翻页
    handlePageChange(page) {
      this.page = page;
      const pages = {
        page: this.page,
        pageSize: this.pageSize,
      };
      this.$emit("changePage", pages);
      this.initTable();
    },
    //更改每页尺寸
    handlerPageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      const pages = {
        page: this.page,
        pageSize: this.pageSize,
      };
      this.$emit("changePage", pages);
      this.initTable();
    },
    getTags(_tags) {
      let tags = _tags;
      if (!tags) {
        return "";
      }
      if (typeof tags === "string") {
        tags = tags.split(",");
      }
      let list = [];
      for (let i = 0, n = tags.length; i < n; i++) {
        let tag = tags[i];
        if (tag) {
          list.push(tag);
        }
        if (list.length === 2) {
          break;
        }
      }
      return list.join("/");
    },
    //过滤器响应回调
    // selectFilter(obj) {
    //   if (obj) {
    //     for (let key in obj) {
    //       this.curFilterData[key] = obj[key];
    //       this.$set(this.curFilterData, key, this.curFilterData[key]);
    //     }
    //   }
    //   this.changeFilter();
    //   this.page = 1;
    //   this.initTable();
    // },
    selectFilter(value) {
      let filterObj = {};
      for (let key in value) {
        filterObj[key] = value[key];
      }
      this.page = 1;
      this.filterData = filterObj;
    },
    async goBrandDetail(code) {
      const res = await GetDetailBrowsingRecord({
        module: "businessDetail",
        detailID: code,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/businessDetail", {
          id: code,
        });
      }
    },
    search(key) {
      this.searchKey = key;
      this.initData();
    },
  },
};

export default brandMixin;
