<template>
  <div class="site-wrap">
    <Header
      :mode="2"
      :searchKey="searchKey"
      :cityCode="cityCode"
      @search="search"
    ></Header>
    <div class="main-wrap">
      <MapListTemplate @changed="handleChangeExpand">
        <div
          class="left-panel no-scrollbar"
          :class="{ 'no-pager': isHidePager }"
          slot="leftPanel"
          v-loading="isLoading"
        >
          <div class="left-navs">
            <ul>
              <li @click="hideCollectList">
                <FilterBtn
                  :data="filterMenusData"
                  :filterData="curFilterData"
                  :isSelectClose="true"
                  @selectFilter="selectFilter"
                >
                  <Icon custom="icon-filter"></Icon>
                  高级筛选
                </FilterBtn>
              </li>
              <li
                :class="{ active: isShowCollection }"
                @click="changeCollectShow"
              >
                <Icon custom="icon-favorite"></Icon>
                我的收藏
              </li>
            </ul>
          </div>
          <div
            class="left-list-content"
            :class="{ 'g-login-padding': !isLogin() }"
          >
            <ul>
              <li
                v-for="(item, index) in showBrandList"
                :key="'brand_' + index"
                :class="{ cur: curBrandId === item.code }"
                @click="handleSelectBrand(item)"
                :ref="`brandListItem${index}`"
              >
                <div
                  class="pic cur-pointer"
                  @click.stop="goBrandDetail(item.code,item.cityName)"
                >
                  <span class="pic-name text-ellipsis2 center-middle-box">{{
                    item.name
                  }}</span>
                  <img :src="item.logo || showBgPic(index)" alt="" />
                </div>
                <div class="list-cont">
                  <div>
                    <span class="title">{{ item.name }}</span>
                    <span
                      class="collect"
                      v-if="!item.isCollect"
                      @click.stop="addCollect(item.code)"
                      >收藏</span
                    >
                    <span
                      class="collect no-collect"
                      @click.stop="delCollect(item.code)"
                      v-else
                      >取消收藏</span
                    >
                  </div>
                  <div class="list-item">
                    <span class="list-item2">
                      商圈级别: <a>{{ item.level || "--" }}</a>
                    </span>
                    <span class="list-item2">
                      商圈等级: <a>{{ item.type || "--" }}</a>
                    </span>
                  </div>
                  <div class="list-item">
                    商圈内有: <a>{{ item.childrenNumber }}个商业项目</a>
                    <span
                      class="link hover-show"
                      @click.stop="goBrandDetail(item.code,item.cityName)"
                      >查看详情</span
                    >
                  </div>
                </div>
                <div class="cur-box"></div>
                <img
                  class="triangle-icon"
                  src="../../assets/images/icon/triangle.png"
                  alt=""
                />
              </li>
              <template v-if="!isLogin()">
                <li class="visitor-login">
                  <div
                    role="button"
                    class="visitor-login-btn"
                    @click="gotoLogin"
                  >
                    登录查看全部信息<img
                      class="visitor-login-icon"
                      :src="require('@/assets/images/double-arror.png')"
                      width="14"
                      height="14"
                    />
                  </div>
                </li>
              </template>
            </ul>
            <div
              class="no-data"
              v-if="!isLoading && showBrandList.length === 0"
            >
              <no-data class="center-middle-box"></no-data>
            </div>
            <!-- <Spin fix v-if="isLoading"></Spin> -->
          </div>
          <PagerLittle
            v-if="showBrandList.length"
            class="left-list-pager"
            :total="total"
            :page="page"
            :pageSize="pageSize"
            @on-change="handlePageChange"
          ></PagerLittle>
        </div>
        <MapPanel
          ref="mapPanel"
          :isCity="true"
          :isSelectCity="true"
          @loaded="handleMapLoaded"
          @click="handleMapClick"
          @selectCity="handleSelectCity"
        >
        </MapPanel>
      </MapListTemplate>
    </div>
  </div>
</template>

<script>
import MapPanel from "@/components/MapPanel.vue";
import FilterBtn from "@/components/filter/FilterBtn.vue";
import PagerLittle from "@/components/PagerLittle";
import * as turf from "@turf/turf";
import { openNewWindow, login, isLogin } from "js/utils.js";

import { brandFilterMenus } from "js/business.js";

import InfoWindowBrand from "@/components/InfoWindowBrand.vue";
import brandMixin from "./business";
import collectMixin from "@/mixins/collect";
import Vue from "vue";
import { GetDetailBrowsingRecord } from "@/service";
import normalPic from "@/assets/images/business/logobg1.png";
import normalPic2 from "@/assets/images/business/logobg2.png";
export default {
  metaInfo: {
    title: "查商圈",
    meta: [
      {
        name: "keywords",
        content:
          "千里目,全国商圈,商圈数据,商圈分析,商圈排名,区域数据,商圈分布,城市商圈数量,商圈占地面积,商圈级别,商圈等级,入驻商圈商场数量,对比分析",
      },
      {
        name: "description",
        content:
          "千里目覆盖全国各地商圈数据，具备精准搜索、通过地图快速定位、对比分析等功能，支持查看全国商圈位置分布、不同城市商圈数量、商圈占地面积、商圈级别、商圈等级、入驻商圈商场数量等详细数据。",
      },
    ],
  },
  props: [""],
  data() {
    return {
      selectCityName: "",
      // brandList: [],
      curBrandId: "",
      // page: 1,
      // pageSize: 20,
      // total: 0,
      normalPic: normalPic,

      // 收藏相关
      isShowCollection: false,
      collectList: [],
      mapPanel: null,
      isMapLoaded: false,
      deep: 1,
      districtCache: new Map(), // 行政区划缓存
      brandCountCache: new Map(), // 品牌地图数据缓存
      featuresMap: new Map(), // 每一级的features
      deepGeomMap: new Map(), // 每一级的geom
      deepAdCode: {}, // 每个层级
      isShowGroup: true, // 是否显示点位数量
      cityProvinceMap: new Map(), // 省和市的对应关系
      provinceCityMap: new Map(),
      cityNameMap: new Map(),
      isLoadedCityProvince: false,
      cityCode: "",
    };
  },
  mixins: [brandMixin, collectMixin],
  computed: {
    filterMenusData() {
      return {
        title: "高级筛选",
        icon: "ios-funnel-outline",
        filters: brandFilterMenus,
      };
    },
    showBrandList() {
      if (this.isShowCollection) {
        return this.collectList;
      }
      return this.brandList;
    },
    showBgPic() {
      return function (val) {
        if (val % 2 === 0) {
          return normalPic2;
        }
        return normalPic;
      };
    },
    sort() {
      return this.$store.getters.getSortId("business");
    },
    isHidePager() {
      return this.isShowCollection || this.total < this.pageSize;
    },
  },
  watch: {
    isShowGroup(newval) {
      if (newval) {
        this.mapPanel.closeWindowInfo();
      }
    },
  },
  methods: {
    // ******** map ********

    handleMapLoaded() {
      this.isMapLoaded = true;
      this.initData();
    },
    isLogin,
    gotoLogin: login,
    // 切换城市
    async handleSelectCity(city) {
      this.cityCode = city.adcode;
      this.initData();
    },

    // 商圈点击
    handleMapClick(feature) {
      if (!feature) {
        return;
      }
      this.curBrandId = feature.properties.id || "";
      let divHeight = this.$refs.brandListItem0[0].offsetHeight;
      let topDistance = 0;
      for (let i in this.showBrandList) {
        if (this.showBrandList[i].code === feature.properties.id) {
          topDistance = Number(i) * divHeight;
        }
      }
      this.scrollToBottom(topDistance);
      this.openWindowInfo(feature);
    },
    //列表滚动到某个位置
    scrollToBottom(topDistance) {
      this.$nextTick(() => {
        let list = this.$el.querySelector(".left-list-content");
        list.scrollTop = topDistance; //滚动到距离元素顶部 40px
      });
    },
    openWindowInfo(feature) {
      let properties = feature.properties;
      let polygon = turf.polygon(feature.geometry.coordinates);
      let center = turf.centerOfMass(polygon);
      let coordinates = center.geometry.coordinates;
      let InfoWindow = Vue.extend(InfoWindowBrand);
      let instance = new InfoWindow({
        propsData: {
          title: properties.name,
          list: [
            `商圈级别: ${properties.level}`,
            `商圈等级: ${properties.type}`,
            `商圈内有: ${properties.childrenNumber}个商业项目`,
          ],
          btnText: "查看详情",
        },
      });
      instance.$on("doSubmit", async () => {
        const res = await GetDetailBrowsingRecord({
          module: "businessDetail",
          detailID: properties.code || properties.id,
        });
        if (res && res.code == 200 && res.data == "success") {
          openNewWindow("/businessDetail", {
            id: properties.code || properties.id,
            cityName:properties.cityName
          });
        }
      });
      instance.$mount();
      let content = instance.$el;
      let that = this;
      that.mapPanel.openInfoWindow(coordinates, content, {
        offset: [0, -23],
        close() {
          that.curBrandId = "";
        },
      });
    },

    // 选择左侧商圈
    handleSelectBrand(item) {
      if (this.curBrandId === item.code) {
        return;
      }
      this.curBrandId = item.code;
      this.openLeftItem(item);
    },
    openLeftItem(item) {
      let feature = this.getFeatureByItem(item);
      this.openWindowInfo(feature);
      //this.mapPanel.fitPointBounds(feature, { immediately: true });
      this.mapPanel.fitBounds(feature);
    },
    async goBrandDetail(code,cityName) {
      const res = await GetDetailBrowsingRecord({
        module: "businessDetail",
        detailID: code,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/businessDetail", {
          id: code,
          cityName:cityName
        });
      }
    },
    getFeatureByItem(item) {
      let geom = item.geom;
      if (typeof geom === "string") {
        geom = JSON.parse(geom);
      }
      let feature = {
        type: "Feature",
        geometry: geom,
        properties: item,
      };
      return feature;
    },
    // 绘制面
    getMapPolygon(list) {
      if (!list.length || !this.isMapLoaded) return;
      let features = [];
      list.forEach((item) => {
        let geom = JSON.parse(item.geom);
        let feature = {
          type: "Feature",
          geometry: geom,
          properties: {
            name: item.name,
            id: item.code,
            level: item.level,
            type: item.type,
            childrenNumber: item.childrenNumber,
            cityName:item.cityName
          },
        };
        features.push(feature);
      });
      let options = {
        strokeColor: "#3519FB",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: "#3519FB",
        fillOpacity: 0.2,
        strokeStyle: "solid",
      };
      this.mapPanel.fitBounds(features, false, [100, 100, 100, 100]);
      this.mapPanel.setPolygons("polygons", features, options);
    },
    // ******** end map ********
    handleChangeExpand() {
      this.map && this.map.resize();
    },
  },
  mounted() {
    this.cityCode = localStorage.getItem("cityCode", "cityCode");
    this.mapPanel = this.$refs.mapPanel;
    let searchKey = this.$route.query.key;
    if (searchKey) {
      this.searchKey = searchKey;
    }
    if (this.mapPanel) {
      setTimeout(() => {
        this.mapPanel.changeCityName("北京市");
      }, 3000);
    }
  },
  components: {
    MapPanel,
    FilterBtn,

    PagerLittle,
    // NoData
  },
};
</script>

<style lang="less">
.circle-blue {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: hsla(180, 100%, 50%, 0.7);
  border: 1px solid hsl(180, 100%, 40%);
  box-shadow: hsl(180, 100%, 50%) 0px 0px 1px;
}
.group-marker {
  width: 57px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  color: #fff;
  background-image: url("../../assets/images/marker/marker_group.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
